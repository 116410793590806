import { convertObjectToUrlParams } from './form';
import { ABORT_ERROR } from '../constants/errorNames';

export async function getUrlFromBusinessName({ businessName, recaptchaToken, abortContext }) {
    if (abortContext.getUrlAbortController) {
        abortContext.getUrlAbortController.abort();
    }

    abortContext.getUrlAbortController = new AbortController();

    const formData = new FormData();
    formData.append('businessName', businessName);
    formData.append('token', recaptchaToken);

    try {
        const res = await fetch('/api/site/getUrlFromBusinessName', {
            method: 'POST',
            signal: abortContext.getUrlAbortController.signal,
            body: formData
        });

        if (res.status !== 200) {
            throw new Error(`Request failed with status ${res.status}`);
        }

        const body = await res.json();

        return { url: body.url };
    } catch (e) {
        return { error: true };
    }
}

export async function createAccount({ registrationData, abortContext }) {
    if (abortContext.getUrlAbortController) {
        abortContext.getUrlAbortController.abort();
    }

    abortContext.getUrlAbortController = new AbortController();

    const formData = new FormData();
    formData.append('short', true);
    Object.keys(registrationData).forEach((key) => {
        formData.append(key, registrationData[key]);
    });

    try {
        const res = await fetch('/api/site/signup', {
            method: 'POST',
            signal: abortContext.getUrlAbortController.signal,
            body: formData,
        });

        const json = await res.json();
        return json;
    } catch (e) {
        if (e.name === ABORT_ERROR) {
            return { error: true, aborted: true };
        }

        return { error: true };
    }
}

export async function sendBusinessInfo({ data, abortContext }) {
    if (abortContext.getUrlAbortController) {
        abortContext.getUrlAbortController.abort();
    }

    abortContext.getUrlAbortController = new AbortController();

    try {
        const res = await fetch('/api/site/setupAccount', {
            method: 'POST',
            signal: abortContext.getUrlAbortController.signal,
            body: convertObjectToUrlParams(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        });

        const json = await res.json();
        return json;
    } catch (e) {
        window.Rollbar?.error(e);

        return { error: true };
    }
}

export async function saveSignupData({ lang, url, placeName, email, phone }) {
    const formData = new FormData();

    formData.append('lang', lang);
    formData.append('url', url);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('placeName', placeName);

    const res = await fetch('/api/site/signupSave', {
        method: 'POST',
        body: formData,
    });
}

/**
 *
 * @param {string} url
 * @returns {Promise<boolean>}
 */
export function isAccountExists(url) {
    if (!url) {
        return Promise.resolve(false);
    }

    const body = new FormData();
    body.append('url', url);

    return fetch(
        '/api/site/isExistsUrl',
        {
            method: 'POST',
            body,
        }
    )
        .then(r => r.ok && r.json())
        .then(r => r?.success)
        .catch(e => {
            console.error(e.message);
            return Promise.resolve(false);
        });
}

/**
 *
 * @param {string} email
 * @param {string} [token]
 * @returns {Promise<string|boolean>}
 */
export function sendDomainReminderEmail(email, token) {
    if (!email) {
        return Promise.resolve(false);
    }

    const body = new FormData();
    body.append('email', email);

    if (token) {
        body.append('token', token);
    }

    return fetch(
        '/ua/accountRecoveryAccess/sendDomainReminderEmail',
        {
            method: 'POST',
            body,
        }
    )
        .then(r => r.ok && r.json())
        .then(r => r?.response_code)
        .catch(e => {
            console.error(e.message);
            return Promise.resolve(false);
        });
}
