'use client';

import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocale, useTranslations } from 'next-intl';
import Script from 'next/script';

import ReCAPTCHA from '../../form/ReCAPTCHA';

const whatsAppUrl = 'https://api.whatsapp.com/send/'
    + '?phone=%2B525585266685&text=Quiero+hablar+con+un+vendor.&app_absent=0';

const GatherContactModal = ({ show, onHide }) => {
    const locale = useLocale();
    const t = useTranslations('content/demo');
    const [dontHaveWhatsApp, setDontHaveWhatsApp] = useState(false);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const recaptchaRef = useRef(null);

    const formId = {
        ua: '8fefadb9-b021-43ca-9056-bbd1b0419303',
        en: '00ec6cec-78d3-4a19-b507-97711022eb90',
        mx: 'a19860a2-c1f6-4d8c-abf9-32c75964c76e',
        ru: '55698cd0-4c48-45d6-a555-275b863fe310',
        'ru-kz': '55698cd0-4c48-45d6-a555-275b863fe310',
    }[locale];

    useEffect(() => {
        if (!scriptLoaded || !show) return;

        window.hbspt.forms.create({
            region: 'eu1',
            portalId: '145006115',
            formId,
            target: '#gatherContactForm',
        });
    }, [scriptLoaded, show]);

    const showWhatsAppContactMessage = locale === 'mx' && !dontHaveWhatsApp;
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title className="mt-0">{ t('modal.demo.title') }</Modal.Title>
            </Modal.Header>

            { showWhatsAppContactMessage ? (
                <div className="px-6 text-center py-5">
                    <a className="btn btn-outline-primary" href={whatsAppUrl}>Hablar con un vendedor en WhatsApp</a>
                    <a className="btn btn-link mt-2 text-muted" onClick={() => setDontHaveWhatsApp(true)}>
                        No tengo WhatsApp
                    </a>
                </div>
            ) : (
                <Modal.Body className="p-0">
                    <Script src="https://js.hsforms.net/forms/embed/v2.js" onLoad={() => setScriptLoaded(true)} defer/>
                    <div className="row" id="gatherContactForm"/>
                </Modal.Body>
            )}
            <ReCAPTCHA ref={recaptchaRef}/>
        </Modal>
    );
};

export default GatherContactModal;
