'use client';

import Script from 'next/script';

export default function BlogWhitePaper({ formId }) {
    const hubspotDiv = `<div class="hs-form-frame" data-region="eu1" data-form-id="${formId}" data-portal-id="145006115"></div>`;

    return (
        <div className="bg-cyan rounded-2">
            <Script src="https://js-eu1.hsforms.net/forms/embed/145006115.js" defer/>
            <div className="row">
                <div className="pe-7" id="blogWhitePaperForm" dangerouslySetInnerHTML={{ __html: hubspotDiv }}/>
            </div>
        </div>
    );
}
