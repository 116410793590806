'use client';

import Script from 'next/script';
import { useLocale } from 'next-intl';

export default function BlogSubscribeForm() {
    const lang = useLocale();

    const formId = {
        ua: '4684e637-ca00-4698-8fff-34c79ca9a9d0',
        en: 'c0ec9ba7-bd26-4342-b889-f3504895323a',
        mx: 'e7e67712-dad4-46a1-b7e6-29dbf865138c',
        ru: '39c99bf1-3c9e-400f-82ba-4728450b21ed',
        'ru-kz': '39c99bf1-3c9e-400f-82ba-4728450b21ed',
    }[lang];
    const hubspotDiv = `<div class="hs-form-frame" data-region="eu1" data-form-id="${formId}" data-portal-id="145006115"></div>`;

    return (
        <div className="bg-light-green rounded">
            <Script src="https://js-eu1.hsforms.net/forms/embed/145006115.js" defer/>
            <div className="row">
                <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8">
                    <div id="blogSubscribeForm" dangerouslySetInnerHTML={{ __html: hubspotDiv }}/>
                </div>
            </div>
        </div>
    );
}
