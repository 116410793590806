'use client';

import { useState } from 'react';
import { useLocale, useTranslations } from 'next-intl';

import IconInline from 'components/icons/IconInline';
import GatherContactModal from './GatherContactModal';
import { getCookie } from '../../../helpers/cookies';
import { openDemoAccount } from '../../../helpers/openExternal';

const DemoButton = ({ className }) => {
    const locale = useLocale();
    const t = useTranslations('content/demo');
    const [demoModalOpen, setDemoModalOpen] = useState(false);

    const openDemoModal = () => {
        if (!getCookie('demo_contact_form') && !getCookie('account_url')) {
            setDemoModalOpen(true);
            return;
        }

        openDemoAccount(locale);
    };

    return (
        <>
            <a className={`btn btn-outline-primary ${className}`} onClick={openDemoModal}>
                <span className="d-inline-block pe-1">
                    <IconInline name="circle-play"/>
                </span>
                {t('modal.demo.button')}
            </a>

            <GatherContactModal show={demoModalOpen} onHide={() => setDemoModalOpen(false)}/>
        </>
    );
};

export default DemoButton;
